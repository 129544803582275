$main: #000000;
$secondary: #428b4d;
$mustard: #a78b58;
$ternary: #575757;
$gray: #8b8b8b;
$light-gray: #fafafa;
$link: #2a4c7b;
$input-color: #eeeeee;
$dark-text: #272727;
$file-card-text: #575757;
$delete-color: #d30000;
$subheader: #b7b7b7;
$border-gray: #707070;
$divider-color: #eeeeee;
$title-grey: #acacac;

$colors: (
  auth-primary: var(--auth-primary),
  auth-secondary: var(--auth-secondary),
  auth-btn-text: var(--auth-btn-text),
  lightest-gray: #eee,
  light-gray: #b6b6b6,
  medium-gray: #7a7a7a,
  dark-gray: #575757,
  black: #000,
);
